<template>
  <div class="leaderboard-widget">
    <div style="float: right;">
      <b-icon
        class="close-icon"
        icon="x"
        variant="light"
        font-scale="1.5"
        title="Close"
        @click="$emit('hide')"
      ></b-icon>
    </div>
    <div class="header">
      <h4 class="text-center">
        Activity<br>Boards
      </h4>
    </div>
    <div class="leaderboard-tabs">
      <b-tabs content-class="" fill>
        <b-tab title="ALL TIME" active title-item-class="leaderboard-tab">
          <div class="content">
            <div
              class="items"
              v-if="allTime.data && allTime.data.length > 0">
              <leaderboard-item
                v-for="(item, index ) in allTime.data"
                :type="'all'"
                :display="'widget'"
                :item="item"
                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="MONTHLY" title-item-class="leaderboard-tab" lazy>
          <div class="content">
            <div
              class="items"
              v-if="monthly.data && monthly.data.length > 0">
              <leaderboard-item
                v-for="(item, index ) in monthly.data"
                :type="'monthly'"
                :display="'widget'"
                :item="item"
                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="WEEKLY" title-item-class="leaderboard-tab" lazy>
          <div class="content">
            <div
              class="items"
              v-if="weekly.data && weekly.data.length > 0">
              <leaderboard-item
                v-for="(item, index ) in weekly.data"
                :type="'weekly'"
                :display="'widget'"
                :item="item"
                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
  import LeaderboardMixin from '@/mixins/LeaderboardMixin';

  export default {
    mixins : [
      LeaderboardMixin,
    ],
    components : {
      'LeaderboardItem' : () => import('./Item'),
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .leaderboard-widget {
    padding: 1rem 2rem;
    background-color: rgba(231, 98, 202, 0.9);
    box-shadow: 0 3px 10px rgba(119, 119, 119, 0.4);
    border: 3px solid #fff;
    border-top: none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .header {
      h4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2em;
        line-height: 1;
      }
    }

    .leaderboard-tabs {
      a {
        padding: 0.375em;
        font-size: 0.75em;
        color: #fff !important;
        font-weight: bold;

        &.active {
          color: #e762ca !important;
          background: $primary-color;
        }
      }
    }

    .content {
      min-height: 320px;
      padding: 8px;
      
      .no-result {
        font-size: 0.875em;
        color: gray;
        font-style: italic;
      }
    }

    .close-icon {
      background-color: rgba(255,255,255,0.6);
      border-radius: 1rem;
      color: #e762ca !important;
      &:hover {
        background-color: #fff;
        cursor: pointer;
        color: #e762ca !important;
      }
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {

  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {

  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {

  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {

  }
</style>